import { dark } from '@theme-ui/presets';
import { toTheme } from '@theme-ui/typography';
import wp2011 from 'typography-theme-wordpress-2011';
import merge from 'deepmerge';

wp2011.baseFontSize = 20;

export default merge(
  toTheme(wp2011),
  {
    colors: {
      background: '#1B1B1B',
      text: '#F3F3F3',
    },
  },
  dark
);
